#loading_div{
    position: fixed;
    top: 0;
    left: 0;
    background: #1b0f23;
    min-width: 100vw;
    min-height:  100vh;
    display: flex;
    flex-direction:  column;
    flex-wrap:  wrap;
    align-items:  center;
    justify-items: center;
    color: #C9DF00;
    z-index: 999;
    padding: 100px;
}
#loading_div h4{
    margin-top: 25px;
    position: relative;
    margin-left: 25px;
}
#loading_img{
    width: 250px;
    animation: blink 4s;
}
#loading_img_eye{
    width: 250px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    animation: eye 4s;
    animation-fill-mode: forwards;

}
#loading_img_div{
    position: relative;
}

@keyframes eye{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes blink{
    0%{
        opacity: 0;
    }
    20%{
        opacity: 1;

    }
    
    35%{
        opacity: 1;
    }
    45%{
        opacity: 0;
    }
    55%{
        opacity: 1;
    }
    65%{
        opacity: 0;

    }
    75%{
        opacity: 1;
    }
    80%{
        opacity: 0;
    }
    90%{
        opacity: 1;
    }
    98%{
        opacity: 0;
    }
    100%{
        opacity: 1;

    }
}